<ng-container *ngIf="!isChatbotPageActive">

    <div class="eye-logo" [class.inactive]="isChatOpen">
        <div class="outer-circle-white-bg" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)"
            (mousemove)="onMouseMove($event)" [style.--outer-circle-size]="'35px'">
            <div class="outer-circle">
                <div class="middle-circle">
                    <div class="inner-circle"></div>
                </div>
            </div>
        </div>

    </div>


    <div class="chat-container" draggableBlock [class.inactive]="!isChatOpen">

        <div class="header draggable-handle">
            <div class="header__content draggable-handle">
                <div class="header__logo draggable-handle">

                    <div class="outer-circle draggable-handle" [style.--outer-circle-size]="'40px'">
                        <div class="middle-circle draggable-handle">
                            <div class="inner-circle draggable-handle"></div>
                        </div>
                    </div>
                </div>
                <span class="header__name draggable-handle">VizBot</span>

            </div>
            <div class="header__buttons">
                <!-- <button class="chat-close-button icon icon-add" (click)="clearChat()"></button> -->
                <button class="chat-close-button icon icon-fullscreen" (click)="openChatbotPage()"></button>
                <button class="chat-close-button icon icon-close" (click)="toggleChat()"></button>

            </div>

        </div>

        <div class="chat-input-area">
            <oux-spinner [show]="loading$ | async" [size]="'large'" [hasOverlay]="true"></oux-spinner>

            <ul class="messages" #messageList (scroll)="onScroll($event)">
                <div class="message last-message" *ngIf="showDefaultMessage">
                    <div class="bot">
                        <div class="avatar">
                            <div class="logo">

                                <div class="outer-circle">
                                    <div class="middle-circle">
                                        <div class="inner-circle"></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <span class="name">VizBot</span>
                        <span>Hi! I'm your virtual assistant. How may I help you today?</span>
                    </div>
                </div>
                <div *ngFor="let message of currentConversation$ | async ; let last = last; let i = index"
                    class="message" [ngClass]="{'last-message': last}">

                    <div class="user">
                        <div class="avatar">
                            <img *ngIf="message.content.query != null" class="img-responsive" [src]="userAvatar"
                                alt="Directory photo of {{ currentUser }}">
                        </div>
                        <span class="name" *ngIf="message.content.query != null">You</span>
                        <div *ngIf="message.content.query != null">
                            <div>{{ message.content.query }}</div>
                        </div>
                    </div>
                    <div *ngIf="message.html != null" class="divider">

                    </div>
                    <div class="bot">
                        <div class="avatar">
                            <div *ngIf="message.html != null" class="logo">

                                <div class="outer-circle">
                                    <div class="middle-circle">
                                        <div class="inner-circle"></div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <span class="name" *ngIf="message.html != null">VizBot</span>

                        <div *ngIf="message.html != null">
                            <!-- Render Table for 'result' Data -->
                            <div *ngIf="message.resultData && message.resultData.length > 0" class="table-container">
                                <table class="orders-table">
                                    <thead>
                                        <tr>
                                            <th *ngFor="let header of message.tableHeaders">{{ header }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of message.resultData">
                                            <td *ngFor="let header of message.tableHeaders">{{ row[header] }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- Unstructured Data: Display Normal Content -->
                            <div *ngIf="!message.resultData || message.resultData.length === 0" class="html-class">
                                <div *ngIf="isValidHtmlOrPlainText(message)" [innerHtml]="message.html"></div>
                            <!-- <div class="html-class" *ngIf="!message.content.is_answer_unknown" [innerHtml]="message.html"></div> -->
                            <div class="html-class" *ngIf="message.content.is_answer_unknown" >Sorry, VizBot is unable to provide an answer to your query at this time.</div>
                        </div>
                            <div class="sources" *ngIf="!message.content.is_answer_unknown">
                                <span class="sources-label">Sources:</span>
                                <ul class="sources-list">
                                    <li *ngFor="let source of message?.content?.references" class="list-item">
                                        <a [href]="source?.url">{{ source?.title }}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tools">
                                <div class="thumbs-up" [class.inactive]="message?.thumbsDown">
                                    <img [src]="message?.thumbsUp ? 'assets/images/visibility-bot-icons/thumbs-up-active-icon.svg' : 'assets/images/visibility-bot-icons/thumbs-up-inactive-icon.svg'"
                                        [alt]="message?.thumbsUp ? 'Thumbs Up' : 'Thumbs Down'"
                                        (click)="toggleThumbsUp(i)">
                                </div>
                                <div class="thumbs-down" [class.inactive]="message?.thumbsUp">
                                    <img [src]="message?.thumbsDown ? 'assets/images/visibility-bot-icons/thumbs-down-active-icon.svg' : 'assets/images/visibility-bot-icons/thumbs-down-inactive-icon.svg'"
                                        [alt]="message?.thumbsDown ? 'Thumbs Up' : 'Thumbs Down'"
                                        (click)="toggleThumbsDown(i)">
                                </div>
                                <div class="copy" (click)="copyToClipboard(i)">
                                    <img *ngIf="!message?.copiedStatus" class="copy-icon"
                                        src="assets/images/visibility-bot-icons/copy-icon.svg" alt="Copy Icon">
                                    <span *ngIf="message?.copiedStatus">Copied!</span>
                                </div>

                            </div>
                            <div class="bad-feedback" *ngIf="message?.thumbsDown">
                                "We are sorry that the response based on my knowledge base is not up to the mark, please
                                send email to <a href="mailto:visibility-ops@cisco.com" style="color: var(--link);">visibility-ops@cisco.com </a>for
                                further
                                details and help with your question."
                            </div>
                        </div>
                    </div>






                </div>
            </ul>
            <div class="input-bar">
                <div class="message-input">
                    <i class="icon icon-search"></i>
                    <input #input type="text" [disabled]="isSearching" placeholder="How can I help?" required
                        autocomplete="off" [(ngModel)]="userInput"
                        (keyup.enter)="sendMessage(userInput)" ouxThemeable />

                    <i class="icon icon-microphone" *ngIf="!isMicOn" (click)="startRecognition()"></i>


                    <i class="icon icon-mute" *ngIf="isMicOn" (click)="stopListening()"></i>
                    <div *ngIf="showPopUp" class="popup">
                        Start speaking...
                      </div>

                    <button type="submit" (click)="sendMessage(userInput)"><i class="icon icon-send"></i></button>

                </div>
                <div class="chatbot-disclaimer">
                    <p >
                        VizBot can make mistakes. Please verify important info. <br> 
                      </p>
                </div>
            </div>

        </div>

    </div>
</ng-container>